import React from "react"
import { graphql } from "gatsby"
import { SectionContainer } from "../components/micros"
import Layout from "../components/layout"
import styled from "styled-components"


// this is deaflut post tamplate

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <BlogSection>
        <h1>{frontmatter.title}</h1>
        {/* <h2>{frontmatter.date}</h2> */}
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </BlogSection>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD MMMM , YYYY")
        slug
        title
        author
      }
    }
  }
`

const BlogSection = styled(SectionContainer)`
padding: 150px 15%;
text-align:left;
line-height:1.2em;
font-family: 'Merriweather','Georgia',serif;

>h1{
  letter-spacing:0px;
  font-family: 'Roboto Serif', serif !important;
  margin:50px 0px;
}

>div{
/* text-align: justify; */
letter-spacing:1px;
}

> div > h2{
  letter-spacing:0px;
  margin:35px 0px 20px 0px ;
  font-family: 'Roboto Serif', serif !important;
}
`


